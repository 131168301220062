import Script from 'next/script';
import { useEffect, useState } from 'react';
import { configs } from './ChatbotConfigs';

/* eslint-disable @typescript-eslint/no-explicit-any */
declare global {
	interface Window {
		widgetObj: any;
		configureWidget: (config: Vendors.ChatbotConfig) => void;
		config: Vendors.ChatbotConfig;
	}
}

export interface ChatbotProps {
	tenantId: string;
	settingsId: '8' | '9' | '11' | string;
	chatbotConfiguration: Vendors.ChatbotConfig;
	chatIntegrityOld?: string;
	chatIntegrityNew?: string;
}

export const Chatbot = ({ tenantId, settingsId, chatbotConfiguration, chatIntegrityOld, chatIntegrityNew }: ChatbotProps) => {
	const [mainjsLoaded, setMainjsLoaded] = useState(false);

	useEffect(() => {
		window.widgetObj?.destroyWidget?.();
		if (!chatbotConfiguration) return console.log('no chatbot configuration');
		if (!window.configureWidget) return console.log('no configureWidget');
		const customConfig = {
			...chatbotConfiguration,
			cssOverwrites: configs[settingsId]?.cssOverwrites ?? configs[0]?.cssOverwrites, // fallback to first config
			hideWidgetUnlessOngoingChat: false,
		};
		if (tenantId) {
			customConfig.visitorInfo?.fields?.push({ name: 'entrypointSubject', type: 'hidden', defaultValue: tenantId }); //tenantId = 1008
		} else {
			customConfig.hideWidgetUnlessOngoingChat = true;
		}
		window.widgetObj = window.configureWidget(customConfig);
	}, [mainjsLoaded, tenantId, settingsId, chatbotConfiguration]);

	return (chatIntegrityNew && chatIntegrityOld) && (
		<Script
			src="https://supchat.skat.supwizapp.com/widget/main.js"
			strategy="lazyOnload"
			integrity={chatIntegrityNew?.length && `sha256-${chatIntegrityNew} ${chatIntegrityOld?.length ? `sha256-${chatIntegrityOld}` : ''}`}
			crossOrigin="anonymous"
			onLoad={() => setMainjsLoaded(true)}
		/>
	);
};
